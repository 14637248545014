/* global window, document */

/**
 * @file
 *
 * Adjust DOM elements with the .full-width class name to the right width.
 * Use this script with the scss/utilities/_full-width.scss file.
 */

Drupal.acreat = Drupal.acreat || {};

Drupal.acreat.cssFullWidth = Drupal.acreat.cssFullWidth || {
  // Initialization: use it to set up the system.
  init(context, settings) {
    jQuery(window).on(
      "resize",
      { context, settings },
      this.onWindowResize.bind(this)
    );
    jQuery(window).trigger("resize");
  },

  // Function: convert a number of pixels (without unit) into a viewport width
  // string.
  pxToVw(value) {
    return `${(value * 100) / window.innerWidth}vw`;
  },

  // Event callback: reset DOMElements styles within a given context.
  onWindowResize(e) {
    jQuery(".full-width", e.data.context).each(this.resetStyles);
  },

  // jQuery .each() callback: reset a DOMElement styles.
  resetStyles(idx, domElement) {
    const $element = jQuery(domElement);
    const sidebarWidth = window.innerWidth - document.body.clientWidth;
    const width = $element.parent().width();
    const offsetLeft = $element.parent().offset().left;
    const offsetRight = window.innerWidth - width - offsetLeft - sidebarWidth;

    $element.css({
      position: "static",
      marginLeft: Drupal.acreat.cssFullWidth.pxToVw(-1 * offsetLeft),
      marginRight: Drupal.acreat.cssFullWidth.pxToVw(-1 * offsetRight),
      width: `calc(100vw - ${sidebarWidth}px)`
    });
  }
};
