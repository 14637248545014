Drupal.acreat = Drupal.acreat || {};

Drupal.acreat.spezialNav = {
  init(spezialNav) {
    const items = jQuery("a[data-background]", spezialNav);
    items.on("mouseover", this.onItemMouseOver.bind(this));
    jQuery(spezialNav)
      .on("mouseleave", this.onContextMouseLeave.bind(this))
      .trigger("mouseleave");
  },
  onContextMouseLeave(event) {
    jQuery("a[data-background]", event.currentTarget)
      .first()
      .trigger("mouseover");
  },
  onItemMouseOver(event) {
    const item = jQuery(event.currentTarget);
    const spezialNav = jQuery(event.currentTarget).parents(".spezial-nav");
    jQuery("a[data-background]", spezialNav).removeClass("hover");
    item.addClass("hover");
    this.setBackground(item);
  },
  setBackground(item) {
    const image = item.data("background");

    if (image) {
      item.parents(".spezial-nav").css({
        backgroundImage: `url(${image})`
      });
    }
  }
};
