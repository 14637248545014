/**
 * @file
 *
 * This script aim to add classes to drupal form items according their form
 * elements states.
 *
 * Available states: focus, empty, filled, valid, invalid.
 */

Drupal.acreat = Drupal.acreat || {};

Drupal.acreat.formItemStates = Drupal.acreat.formItemStates || {
  // Property: A form elements CSS selector.
  selector: "input, select, textarea",

  // Init: set up listeners and establish initial states.
  init(context) {
    this.addJquerySelectors();
    jQuery(this.selector, context)
      .once("formItemStatesInit")
      .on("focus blur", this.setStates);
    jQuery(this.selector, context).each(this.setStates);
  },

  // Add jQuery selectors: extends jQuery with new CSS selectors.
  addJquerySelectors() {
    const elementInList = (elem, list) => Array.from(list).indexOf(elem) > 0;

    const selectors = {
      "in-range": elem => {
        const list = document.querySelectorAll(":in-range");
        return elementInList(elem, list);
      },
      "out-range": elem => {
        const list = document.querySelectorAll(":out-range");
        return elementInList(elem, list);
      },
      invalid: elem => {
        const list = document.querySelectorAll(":invalid");
        return elementInList(elem, list);
      },
      valid: elem => {
        const list = document.querySelectorAll(":valid");
        return elementInList(elem, list);
      }
    };

    jQuery.extend(jQuery.expr[":"], selectors);
  },

  // Set states: check form elements states and then update the parent .form-item.
  setStates() {
    const $parent = jQuery(this).parents(".form-item");

    if (jQuery(this).is(":focus")) {
      $parent.addClass("focus");
    } else {
      $parent.removeClass("focus");
    }

    if (jQuery(this).is(":disabled")) {
      $parent.addClass("disabled");
    } else {
      $parent.removeClass("disabled");
    }

    if (jQuery(this).is("[readonly]")) {
      $parent.addClass("readonly");
    } else {
      $parent.removeClass("readonly");
    }

    if (jQuery(this).val().length) {
      $parent.addClass("filled");
    } else {
      $parent.removeClass("filled");
    }

    if (jQuery(this).val() !== "" && jQuery(this).is(":invalid")) {
      $parent.addClass("invalid");
    } else {
      $parent.removeClass("invalid");
    }

    if (jQuery(this).is(":valid")) {
      $parent.addClass("valid");
    } else {
      $parent.removeClass("valid");
    }

    if (jQuery(this).is("in-range")) {
      $parent.addClass("in-range");
    } else {
      $parent.removeClass("in-range");
    }

    if (jQuery(this).is("out-range")) {
      $parent.addClass("out-range");
    } else {
      $parent.removeClass("out-range");
    }
  }
};
