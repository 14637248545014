Drupal.behaviors.fancybox = {
  attach() {
    const options = {
      backFocus: false,
      protect: true,
      buttons: ["zoom", "thumbs", "close"],
      animationEffect: "slide",
      animationDuration: 500,
      thumbs: {
        axis: "x"
      },
      transitionEffect: "zoom-in-out",
      lang: jQuery("html").attr("lang"),
      i18n: {
        fr: {
          CLOSE: "Fermer",
          NEXT: "Suivant",
          PREV: "Précédent",
          ERROR:
            "La requête n'a pu être chargée. <br/> Svp ressayer plus tard.",
          PLAY_START: "Démarrer le slideshow",
          PLAY_STOP: "Mettre en pause le slideshow",
          FULL_SCREEN: "Plein écran",
          THUMBS: "Visionneuse",
          DOWNLOAD: "Télécharger",
          SHARE: "Partager",
          ZOOM: "Zoom"
        }
      }
    };

    jQuery("[data-fancybox]").fancybox(options);
  }
};
