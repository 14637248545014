// Slick
Drupal.behaviors.slick = {
  attach(context) {
    jQuery(".slick", context).each((idx, elt) => {
      const options = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: []
      };

      if (jQuery(elt).hasClass("slick--fade")) {
        options.fade = true;
      }

      if (jQuery(elt).hasClass("slick--dots")) {
        options.dots = true;
      }

      if (jQuery(elt).hasClass("slick--overflow")) {
        options.centerMode = true;
        options.centerPadding = "10vw";
        options.slidesToShow = 2;
        options.responsive.push({
          breakpoint: 768,
          settings: {
            slidesToShow: 1
          }
        });
      }

      jQuery(elt).slick(options);
    });
  }
};
