/* global window, document, jQuery */

import "@fancyapps/fancybox";
import "slick-carousel";

import "./behaviors/cssFullWidth";
import "./behaviors/fancybox";
import "./behaviors/formItemStates";
import "./behaviors/isotopeFancybox";
import "./behaviors/localeSelect";
import "./behaviors/scrollFix";
import "./behaviors/slick";
import "./behaviors/spezialNav";
