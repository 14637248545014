// Isotope Fancybox.
//
// When Isotope arrange is complete, hide fancybox contents if a content in the
// isotope grid is hidden.

Drupal.behaviors.isotopeFancybox = {
  attach() {
    jQuery(document).on("arrangeComplete", ".js-isotope", this.arrangeComplete);
  },
  arrangeComplete(event, filteredItems) {
    const items = [];

    filteredItems.forEach(object => {
      items.push(object.element);
    });

    jQuery(".js-isotope__item", event.currentTarget).each((idx, elt) => {
      const fancyboxItem = jQuery("[data-fancybox]", elt);
      const suffix = "-isotope-hidden";
      let rel = fancyboxItem.attr("data-fancybox").replace(suffix, "");

      if (!items.includes(elt)) {
        rel += suffix;
      }

      fancyboxItem.attr("data-fancybox", rel);
    });
  }
};
