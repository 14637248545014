/**
 * @file
 * ScrollFix
 */

class ScrollFix {
  constructor(wrap, options) {
    const defaultOptions = {
      breakpoint: 0
    };

    this.wrap = wrap;
    this.options = Object.assign(defaultOptions, options || {});
    this.fixed = false;

    this.addWindowListeners();
    window.dispatchEvent(new Event("resize"));
  }

  // Methods

  setWrapHeight(height) {
    let newHeight = 0;
    if (!height || height === "auto" || typeof height !== "number") {
      newHeight = this.wrap.querySelector(".scroll-fix__inner").offsetHeight;
    }
    this.wrap.style.height = `${newHeight}px`;
  }

  addWindowListeners() {
    window.addEventListener("resize", this.windowOnResize.bind(this));
  }

  removeWindowListeners() {
    window.removeEventListener("resize", this.windowOnResize);
  }

  addWrapListeners() {
    document.addEventListener("scroll", this.documentOnScroll.bind(this));
    window.addEventListener("load", this.setWrapHeight.bind(this));
  }

  removeWrapListeners() {
    document.removeEventListener("scroll", this.documentOnScroll);
    window.removeEventListener("load", this.setWrapHeight);
  }

  // Event handlers

  documentOnScroll() {
    if (
      (this.wrap.classList.contains("scroll-fix--top") &&
        window.scrollY > this.wrap.offsetTop) ||
      (this.wrap.classList.contains("scroll-fix--bottom") &&
        window.scrollY + window.innerHeight <
          this.wrap.offsetTop + this.wrap.height)
    ) {
      this.wrap.classList.add("scroll-fix--fixed");
      this.fixed = true;
    } else {
      this.wrap.classList.remove("scroll-fix--fixed");
      this.fixed = false;
    }
  }

  windowOnResize() {
    const innerHeight = this.wrap.querySelector(".scroll-fix__inner")
      .offsetHeight;

    if (this.wrap.offsetHeight !== innerHeight) {
      this.setWrapHeight(innerHeight);
    }

    if (window.innerWidth >= this.options.breakpoint && !this.fixed) {
      this.addWrapListeners();
      document.dispatchEvent(new Event("scroll"));
    } else if (window.innerWidth < this.options.breakpoint && this.fixed) {
      this.removeWrapListeners();
    }
  }
}

jQuery.fn.scrollFix = function scrollFix(options) {
  jQuery(this).each(function eachScrollFix() {
    if (!jQuery(this).data("scrollFix")) {
      jQuery(this).data("scrollFix", new ScrollFix(this, options));
    }
  });

  return this;
};

jQuery(document).ready(() => {
  jQuery(".scroll-fix")
    .one("scrollFix")
    .scrollFix();
});

export default ScrollFix;
