// Locale select
Drupal.behaviors.localeSelect = {
  attach() {
    jQuery(document)
      .one("localeSelect")
      .on("click", event => {
        let wrapper = jQuery(null);

        if (jQuery(event.target).is(".language-switch")) {
          wrapper = jQuery(event.target);
        } else {
          wrapper = jQuery(event.target)
            .parents(".language-switch")
            .first();
        }

        if (
          wrapper.length &&
          !wrapper.hasClass("language-switch--open") &&
          event.target.href
        ) {
          event.preventDefault();
        }

        if (wrapper.length) {
          wrapper.toggleClass("language-switch--open");
        }
      });
  }
};
